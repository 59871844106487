@import "../../variables.scss";

.homepage-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .homepage-navbar {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 200px;
        a {
            font-weight: 400;
            font-size: 1.5em;
            margin: 0 15px;
        }
    }

    .name {
        font-size: 5em;
        color: $primary;
    }
    img {
        width: 25%;
    }

    p {
        font-size: 3em;
        color: $white;
    }

    a {
        font-size: 2em;
        color: $primary;
        font-weight: 700;
        transition: all 0.3s;

        &:hover {
            transform: scale(1.3);
            text-decoration: underline;;
        }
    }

}
